
// FOLLOWING SETTINGS WILL BE USED FOR DEV site  ******* appsdev.skfilluminate.net
import {extend} from 'lodash';
import {EnvironmentBaseService} from "src/environments/environment-base.service";

export const environment = extend(EnvironmentBaseService, {
  siteenv: 'prod',
  listDataS3Bucket: 'skf-list-data-prod',
  fileUploadS3Bucket: 'skf-amer-bi-prod',
  sapFileMerge: 's3-sftp-prd-sepcanada',
  webAppFilesS3Bucket: 'skf-upload-files-web-apps-prod',
  APIEndpoint: 'https://prodappsapi.skfilluminate.net',
  APIEndPointImportAllocation:'https://9girrbaxri.execute-api.us-east-1.amazonaws.com/Prod',
  APIEndpointMariaDB: 'https://tl2x5mihvl.execute-api.us-east-1.amazonaws.com/Prod',
  APISnowflakeEndpoint: 'https://2i32fl4knl.execute-api.us-east-1.amazonaws.com/Prod',
  loginurl: 'https://apps-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=4eu9bndlt3f927gnrkosh6t80f&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://apps.skfilluminate.net',
});
